@use 'sass:map';
@use './vars';

%remove-outline {
  outline: 0;

  &::-moz-focus-inner {
    border: 0;
  }
}

%remove-link-defaults {
  a:link {
    color: var(--rearea-black-primary, #272727);
    text-decoration: none;
  }

  a:visited {
    color: var(--rearea-black-primary, #272727);
    text-decoration: none;
  }

  a:hover {
    color: var(--rearea-black-primary, #272727);
    text-decoration: none;
  }

  a:active {
    color: var(--rearea-black-primary, #272727);
    text-decoration: none;
  }
}

%width-restrict {
  max-width: map.get(vars.$grid, "normal-width");
  margin: {
    left: auto;
    right: auto;
  }
  padding: {
    left: map.get(vars.$grid, "gutter");
    right: map.get(vars.$grid, "gutter");
  }
}

%full-screen {
  &.\--full-screen {
    @extend %width-restrict;
  }
}
